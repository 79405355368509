<template>
  <div class="page-push">
    <div class="header drag">
        <div class="logo " >
            <img class="img" src="https://resources.oz4.cn/mashang/mash_logo_light.png"></img>
        </div>
        <div class="room-info no-drag">
          <div class="room-logo">
              <img class="img" :src="room_info.cover_img"></img>
          </div>
          <div class="room-name">
            {{room_info.name}}
          </div>
          <!-- <div class="preview" @click="preview()">
              预览
          </div> -->
        </div>
        <div class="nav no-drag">

        </div>

        <Poptip class="user-info no-drag" trigger="click" content="提示内容" placement="bottom-end">
            <div class="user no-drag" >
                <img class="img" src="https://panel.oz4.cn//upload/common/images/20220610/20220610101119165487027950712.png" ></img>
                <font class="name">{{room_info.anchor_name}}</font>
                <Icon type="ios-arrow-down" />
            </div>
            <div class="user-con" slot="content">
                <div class="item" @click="switchRoom()">
                    切换直播间
                </div>
                <div class="item" @click="userOut()">
                    退出
                </div>
            </div>
          </Poptip>
        <window-setting></window-setting>
      </div>
    <div id="content">
      <div class="center">
        <!-- //消息审核区域 -->
        <!-- <comp-chat-review></comp-chat-review> -->

        <div class="chat-review-list">
          
          <div class="item" v-for="item,index in reviewMsgList" :key="index">
              <div class="user">
                <!-- <img src="" alt=""> -->
                <div class="text">{{item.nick== item.userID ? item.nick:item.userID}}：</div>
              </div>
              <div class="msg">
                  {{item.content}}
              </div>
              <div class="tools-bar">
                <div class="reject" @click="rejectReView(index)">
                  删除
                </div>
              </div>
          </div>

        </div>
        <div class="pass-all" @click="passReView()">全部过审</div>
      </div>
      <div class="right" >
        <div class="tab">
            <div class="item item-user" :class="tab_active == 'user' ? 'active':''" @click="switchTab('user')">
                <icon-svg icon-class="icon-canyuhuati"></icon-svg>
                <font class="name">观众</font>
            </div>
        </div>
        <div class="tab-con tab-con-user" v-show="tab_active == 'user'">
          <div class="user-list">
            <div class="title">在线观众</div>
            <comp-participants></comp-participants>
          </div>
          <div class="msg-list">
            <div class="title">消息互动</div>
            <comp-chat ref="compChat" @msgPush="getMsg"></comp-chat>
          </div>
        </div>
      </div>
    </div>
    <!-- <room-beauty-dialog ref="roomBeautyDialog"></room-beauty-dialog> -->
  </div>
</template>
<script>

// const sdkAppId = 1400605398;
// const secretKey = 'cf286a8d47da6215f437ec6b2313489390cb3b337e949ba7baf756753af16da5';

const sdkAppId = 1400774202;
const secretKey = 'ecd5b95d333534139c1426765747b3696ca007aa889f24abfca0e4cd2c8d0861';

import compParticipants from '@/components/comp-participants';
import compChat from '@/components/comp-chat';

import compChatReview from '@/components/comp-chat-review';

import windowSetting from '@/components/comp-window-setting/window-setting';


export default {
  name: 'chat-review',
  data() {
    return {
      room_info: {
      },
      tab_active:'user',
      anchorUserInfo: {
        // 用户ID
        userId: 'review',
        // 用户昵称
        userName: '评论审核员',
        // 用户头像
        userAvatar: '',
      },
      roomInfo: {
        // 房间id
        roomId: null,
        // 房间昵称
        roomName: null,
      },
      reviewMsgList: [],
      passMsgList:[],
    }
  },
  components: {
    compParticipants,
    compChat,
    compChatReview,
    windowSetting,
  },
  methods: {
    getMsg(item){
      console.log(item,'获取到新消息~~~~~~~~~~~~');
      this.reviewMsgList.push(item);
    },
    //切换直播间
    switchRoom(){
        this.$Modal.confirm({
              'title': '确认离开直播间吗？',
              'content': '如果您正在开播中，离开直播间将中断当前直播！！',
              'onOk': res => {
                  console.log('ok')
                  this.$router.push({
                    name: 'index',
                  });
              }
        })
    },
    //用户退出
    userOut(){
      this.$Modal.confirm({
              'title': '确认离开直播间吗？',
              'content': '如果您正在开播中，离开直播间将中断当前直播！！',
              'onOk': res => {
                  this.$store.commit('logout');
                  this.$router.push({
                    name: 'signin',
                  });
              }
        })
    },
    //获取直播间信息
    async getRoomInfo(){
      await this.$api.getRoomInfo({
        room_id: this.$route.query.id
      }).then((res) => {
        console.log(res);
        if (res.code >= 0) {
          this.room_info = res.data
          this.roomInfo.roomId = res.data.wechat_room_id
          this.roomInfo.roomName = res.data.name
        }
      });
    },

    //过审
    passReView(){
      console.log('过审');
      
      this.reviewMsgList.forEach((item,index) => {
        console.log(item,'111');
          let user = {
            name:item.nick== item.userID ? item.nick:item.userID,
            avatar:item.avatar
          }
          console.log(user,'useruseruser222')
          this.$refs.compChat.handleSendReViewMsg(item.content,user);
          this.reviewMsgList.splice(index,1);
      });
      // this.$refs.compChat.handleSendReViewMsg('222');
    },

    //删除不过审的评论
    rejectReView(index){
        this.reviewMsgList.splice(index,1);
    },
    //获取主播SIG
    async genUserSig(){
      await this.$api.genUserSig({
        room_id: this.$route.query.id,
        user_id:this.anchorUserInfo.userId
      }).then((res) => {
        console.log(res);
        if (res.code >= 0) {
            this.userSig = res.data.userSig
            let name = res.data.user_id
            name = name.split('$$')
            console.log(name)
            this.anchorUserInfo.userId = res.data.user_id
        }
      });
    },

    // 设置TUIPusher配置信息
    async handlePusherInfo() {

      await this.getRoomInfo()
      await this.genUserSig()

      const userSig = this.userSig

      if (sdkAppId === '' || secretKey === '') {
        alert('basic.Please configure your SDKAPPID');
      }
      console.log( 'handlePusherInfo++++++++++++++++++++')
      console.log(userSig)
      console.log(this.roomInfo)
      console.log(this.anchorUserInfo)
      console.log()
      this.$store.commit('UPDATE_LIVE_STAGE', 'not_started');
      this.$store.commit('SET_APP_INFO', {
        sdkAppId,
        userSig,
      });
      this.$store.commit('SET_ROOM_ID', this.roomInfo.roomId);
      this.$store.commit('UPDATE_ROOM_NAME', this.roomInfo.roomName);
      this.$store.commit('UPDATE_USER_INFO', {
        userId: this.anchorUserInfo.userId,
        userName: this.anchorUserInfo.userName,
        userAvatar: this.anchorUserInfo.userAvatar,
      });
    },
    // 退出直播间
    async handleExit() {
      console.log('重置直播间状态RESET_STATE!!!!!!!');
      await this.$store.commit('RESET_STATE');
      // 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // 处理退出登录
    },
  },
  async created() {
    console.log(this.$util)
    await this.handlePusherInfo();
    this.$eventBus.$on('exit', this.handleExit);
    // this.$eventBus.$on('logout', this.handleLogout);
  },
  mounted() {
    // this.showDevicePresettingDialog();
  },
  beforeDestroy() {
    console.log('页面销毁!!!!!!!')
    this.handleExit();
    this.$eventBus.$off('exit', this.handleExit);
    // this.$eventBus.$off('logout', this.handleLogout);
  },
};
</script>

<style lang="less">
.page-push {
  border-radius: 6px;
  overflow: hidden;
}

.header {
  width:100%;
  height:80px;
  background-image: linear-gradient( 159deg, rgb(42,44,55) 0%, rgb(49,51,65) 100%);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
  // background-image: url('/assets/img/header_bg.png')
  display: flex;
  position: relative;
}

// .header::before {
//   display: block;
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   background-image: url('https://resources.oz4.cn/mashang/push_img/header_bg.png');
// }

.header .logo {
  width: 140px;
  height: 40px;
  margin: 20px;
  display: block;
}

.header .logo img {
  width:100%;
  height: 100%;
  object-fit: cover
}

.header .room-info {
  width: 300px;
  height: 50px;  
  margin: 15px;
  display: flex;
  margin-left: 20px;
}

.header .room-info .room-logo {
  width: 40px;
  height: 40px;
  margin: 2px;
  border-radius: 50px;
  border:3px solid rgba(0,0,0,0.2);
  box-sizing: content-box;
  margin-right: 10px;
}

.header .room-info .room-logo img {
  width:100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.header .room-info .room-name {
  font-size: 16px;
  line-height: 50px;  
  color: #999;
}

.header .nav {
  display: flex;
  margin: 10px;
  margin-left: 20px;
}
.header .nav .item {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  transition: 0.3s;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.header .nav .item:hover {
  background-color: #313341;
  transition: 0.3s;
}
.header .nav .item .icon {
  font-size: 28px;
  color: #777a8e;
}

.header .user-info {
  position: absolute;
  right: 100px;
  top: 25px;
  
}

.header .user {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  padding-right: 10px;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
  background-color: #20222b;
}
.header .user:hover {
  transition: 0.3s;
  background-color: #dbb98e;
} 
.header .user .img {
  display: inline-block;
  width: 26px;
  height: 26px;
  object-fit: cover;
  background-color: #777a8e;
  border-radius: 50px;
  border:2px solid rgba(0,0,0,0.2);
  margin-right: 10px;
}
.header .user .name {
  display: inline-block;
  vertical-align: middle;
}
.header .user .ivu-icon {
  display: inline-block;
  margin-left: 8px;
  vertical-align: 1 !important;
}
.header .user-info .ivu-poptip-body {
  padding:0px ;
}
.header .user-info .ivu-poptip-inner {
  overflow: hidden;
}
.header .user-info .user-con .item {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-indent: 20px;
}
.header .user-info .user-con .item:hover {
  color: #777a8e;
  background-color: rgba(0, 0, 0, 0.16);
}


.header .room-info .preview {
  margin-top: 10px;
  margin-left: 15px;
  line-height: 30px;
  height: 30px;
  width: 60px;
  background-color: #20222b;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  transition: 0.3s;
}

.header .room-info .preview:hover {
  background-color: #dbb98e;
  color: #fff;
  transition: 0.3s;
}

.right {
      width: 380px;
      max-width: 406px;
      height: calc( 100vh - 80px);
      display: flex;
      flex-direction: row;
}

.right .tab {
  width: 80px;
  min-height: 100%;
  background-color: #20222b;
}

.right .tab .item {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: none;
  text-align: center;
  transition: 0.3s;
}

.right .tab .item .icon {
  margin-top:15px;
  font-size:26px;
  margin-bottom: 5px;
}

.right .tab .item.active {
  background-color: #F0CA9C;
  background-image:linear-gradient( -60deg, rgb(148,120,86) 0%,rgb(253,210,160) 100%);
  color: #fff;
  transition: 0.3s;
}

.right .tab-con {
  width: 300px;
  text-align: left;
}
.right .tab-con .title {
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 30px;
  position: relative;
}
.right .tab-con .title::before {
  content: " ";
  position: absolute;
  left: 20px;
  top: 13px;
  width: 3px;
  height: 15px;
  border-radius: 10px;
  background-image:linear-gradient( -60deg, rgb(148,120,86) 0%,rgb(253,210,160) 100%);
}
.right .tab-con .user-list {
    height: 40%;
    display: flex;
    flex-direction: column;
}
.right .tab-con .msg-list {
    height: 60%;
}
#app {

	div#content {
		background-color: #23242b;
		bottom: 0px;
		display: flex;
		height: calc( 100vh - 80px);
		left: 0px;
		right: 0px;
		top: 0px;
		width: 100%;
		div#left {
			background-color: ;
			height: 100%;
			max-width: 100%;
			width: 120px;
		}
		.center {
			flex-grow: 1;
			height: calc(100%);
		}
		
		.column {
			display: flex;
			flex-direction: column;
			padding: 8px;
		}
		
	}
}
</style>
